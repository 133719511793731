window.onload = function () {
  // Splide Slider
  /* const slideHighlightBanner = new Splide('.slide-highlight-banner.splide', {
		type: 'loop',
		pagination: false,
		lazyLoad: 'nearby',
		autoplay: true
	}).mount(); */
  // End Splide Slider
};

/* Function in Page */

function runScriptHomePage() {}

/* End Function in Page */

/* All Function */

function toggleMenuHamburger() {
  document.body.classList.toggle("-open-menu-hamburger");
  document.querySelector(".-hamburger").classList.toggle("-active");
  setTimeout(function () {
    document.querySelector("#main-content").classList.toggle("-hide");
  }, 100);
}

function openPopup() {
  document.body.classList.toggle("-open-popup");
}
function closePopup() {
  document.body.classList.toggle("-open-popup");
}

function runSlideContent() {
  const slideContent = new Splide(".-slide-content.splide", {
    type: "slide",
    lazyLoad: "nearby",
    gap: 16,
    pagination: true,
    updateOnMove: true,
    autoWidth: true,
  }).mount();
}

function runSlideLatesPlayGame() {
  const slideLatesPlayGame = new Splide(
    ".-slide-content.splide.-lates-play-game",
    {
      type: "slide",
      lazyLoad: "nearby",
      gap: 16,
      pagination: true,
      updateOnMove: true,
      autoWidth: true,
    }
  ).mount();
}

function runSlideGames() {
  const slideGames = new Splide(".-slide-content.splide.-games", {
    type: "slide",
    lazyLoad: "nearby",
    gap: 16,
    pagination: true,
    updateOnMove: true,
    autoWidth: true,
  }).mount();
}

function runSlideGamesCategory() {
  const slideGamesCategory = new Splide(
    ".-slide-content.splide.-game-category",
    {
      type: "slide",
      lazyLoad: "nearby",
      gap: 16,
      pagination: true,
      updateOnMove: true,
    }
  ).mount();
}

function runSlideBank() {
  const slideBank = new Splide(".-slide-content.splide.-bank", {
    type: "slide",
    lazyLoad: "nearby",
    gap: 16,
    pagination: true,
    updateOnMove: true,
  }).mount();
}

function filterCat() {
  var isActive = document.querySelectorAll(".-btn-filter a.active");
  [].forEach.call(isActive, function (el) {
    el.className = el.className.replace(/\bactive\b/, "");
  });

  if (window.location.hash == "#new") {
    document.querySelector(".-filter-item").id = "filter-new";
    document.querySelector(".-btn-filter a.-btn-new").classList.add("active");
  } else if (window.location.hash == "#popular") {
    document.querySelector(".-filter-item").id = "filter-popular";
    document
      .querySelector(".-btn-filter a.-btn-popular")
      .classList.add("active");
  } else if (window.location.hash == "#latest") {
    document.querySelector(".-filter-item").id = "filter-latest";
    document
      .querySelector(".-btn-filter a.-btn-latest")
      .classList.add("active");
  } else {
    document.querySelector(".-filter-item").id = "filter-all";
    document.querySelector(".-btn-filter a.-btn-all").classList.add("active");
  }
}

function clickFilterCat() {
  document.querySelector(".-filter-item").id = "";

  window.addEventListener(
    "hashchange",
    function () {
      filterCat();
    },
    false
  );

  filterCat();
}

function changeContentSummaryData(elem) {
  var isActive = document.querySelectorAll(
    ".-box-summary-data .-nav-data a.active"
  );
  var contentActive = document.querySelectorAll(
    ".-box-summary-data .-box-content.active"
  );
  [].forEach.call(isActive, function (el) {
    el.className = el.className.replace(/\bactive\b/, "");
  });
  [].forEach.call(contentActive, function (el) {
    el.className = el.className.replace(/\bactive\b/, "");
  });

  elem.classList.add("active");

  if (elem.classList[0] == "-nav-01") {
    document
      .querySelector(".-box-summary-data .-content-01")
      .classList.add("active");
  } else if (elem.classList[0] == "-nav-02") {
    document
      .querySelector(".-box-summary-data .-content-02")
      .classList.add("active");
  } else if (elem.classList[0] == "-nav-03") {
    document
      .querySelector(".-box-summary-data .-content-03")
      .classList.add("active");
  } else if (elem.classList[0] == "-nav-04") {
    document
      .querySelector(".-box-summary-data .-content-04")
      .classList.add("active");
  } else if (elem.classList[0] == "-nav-05") {
    document
      .querySelector(".-box-summary-data .-content-05")
      .classList.add("active");
  }
}

function changeContentHistoryData(elem) {
  var isActive = document.querySelectorAll(
    ".-box-history-data .-nav-data a.active"
  );
  var contentActive = document.querySelectorAll(
    ".-box-history-data .-box-content.active"
  );
  [].forEach.call(isActive, function (el) {
    el.className = el.className.replace(/\bactive\b/, "");
  });
  [].forEach.call(contentActive, function (el) {
    el.className = el.className.replace(/\bactive\b/, "");
  });

  elem.classList.add("active");

  if (elem.classList[0] == "-nav-01") {
    document
      .querySelector(".-box-history-data .-content-01")
      .classList.add("active");
  } else if (elem.classList[0] == "-nav-02") {
    document
      .querySelector(".-box-history-data .-content-02")
      .classList.add("active");
  }
}

const animateGameCard = () => {
  gsap.set(".-box-title h1", { opacity: 0, y: 20 });
  gsap.set(".-box-title p", { opacity: 0, y: 20 });

  gsap.set(".-list-item .-item", { opacity: 0, y: 20 });

  window.onload = () => {
    gsap.to(".-box-title h1", 0.4, {
      delay: 0.2,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });
    gsap.to(".-box-title p", 0.4, {
      delay: 0.3,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });

    gsap.to(".-list-item .-item", 0.4, {
      delay: 0.5,
      stagger: 0.1,
      opacity: 1,
      y: 1,
      ease: Back.easeOut,
    });
  };
};

const animateGameCardOpen = () => {
  gsap.set(".-box-title h1", { opacity: 0, y: 20 });
  gsap.set(".-box-title p", { opacity: 0, y: 20 });

  gsap.set(".-box-card", { opacity: 0, y: 20 });
  gsap.set(".-card .-detail img", { opacity: 0, scale: 0 });
  gsap.set(".-card .-detail p", { opacity: 0, scale: 0 });
  gsap.set(".-card .-light .-inner", { opacity: 0, scale: 0 });
  gsap.set(".-card .-ribbon .-inner", { opacity: 0, scale: 0 });

  gsap.set(".-game-play .-remark", { opacity: 0, y: 20 });
  gsap.set(".-game-play .-btn-01", { opacity: 0, y: 20 });

  gsap.set(".-content-popup-reward .-box-img", { opacity: 0, scale: 0 });
  gsap.set(".-content-popup-reward .-detail p", { opacity: 0, y: 20 });
  gsap.set(".-content-popup-reward .-detail a", { opacity: 0, y: 20 });

  window.onload = () => {
    gsap.to(".-box-title h1", 0.4, {
      delay: 0.2,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });
    gsap.to(".-box-title p", 0.4, {
      delay: 0.3,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });

    gsap.to(".-box-card", 0.4, {
      delay: 0.4,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });
    gsap.to(".-card", 0.8, { delay: 1, rotationY: 180, ease: Power4.easeOut });
    gsap.to(".-card .-front", 0.4, {
      delay: 1,
      opacity: 1,
      ease: Power4.easeOut,
    });
    gsap.to(".-card .-detail img", 0.4, {
      delay: 1.4,
      opacity: 1,
      scale: 1,
      ease: Back.easeOut,
    });
    gsap.to(".-card .-detail p", 0.4, {
      delay: 1.6,
      stagger: 0.1,
      opacity: 1,
      scale: 1,
      ease: Back.easeOut,
    });
    gsap.to(".-card .-light .-inner", 0.4, {
      delay: 1.4,
      opacity: 1,
      scale: 1,
      ease: Power4.easeOut,
    });
    gsap.to(".-card .-ribbon .-inner", 0.6, {
      delay: 1.5,
      opacity: 1,
      scale: 1,
      ease: Back.easeOut,
    });

    gsap.to(".-game-play .-remark", 0.4, {
      delay: 0.5,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });
    gsap.to(".-game-play .-btn-01", 0.4, {
      delay: 0.6,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });

    setTimeout(() => {
      document.body.classList.add("-open-popup-reward");

      animatePopupReward();
    }, 3000);
  };
};

const animateGameCardAnswer = () => {
  gsap.set(".-box-title h1", { opacity: 0, y: 20 });
  gsap.set(".-box-title p", { opacity: 0, y: 20 });

  gsap.set(".-list-item .-item", { opacity: 0, y: 20 });

  gsap.set(".-answer .-detail img", { opacity: 0, scale: 0 });
  gsap.set(".-answer .-detail p", { opacity: 0, scale: 0 });
  gsap.set(".-answer .-light .-inner", { opacity: 0, scale: 0 });

  window.onload = () => {
    gsap.to(".-box-title h1", 0.4, {
      delay: 0.2,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });
    gsap.to(".-box-title p", 0.4, {
      delay: 0.3,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });

    gsap.to(".-list-item .-item", 0.4, {
      delay: 0.5,
      stagger: 0.1,
      opacity: 1,
      y: 1,
      ease: Back.easeOut,
    });

    gsap.to(".-answer .-light .-inner", 0.4, {
      delay: 1,
      opacity: 1,
      scale: 1,
      ease: Power4.easeOut,
    });
    gsap.to(".-answer .-detail p", 0.4, {
      delay: 1.1,
      stagger: 0.1,
      opacity: 1,
      scale: 1,
      ease: Back.easeOut,
    });
  };
};

const animateGameSafety = () => {
  gsap.set(".-box-title h1", { opacity: 0, y: 20 });
  gsap.set(".-box-title p", { opacity: 0, y: 20 });

  gsap.set(".-box-safety", { opacity: 0, y: 20 });
  gsap.set(".-box-safety .-wheel", { rotation: 0 });
  gsap.set(".-box-safety .-text-open", { opacity: 0 });
  gsap.set(".-box-safety .-door-open", { opacity: 0 });

  gsap.set(".-box-safety .-light .-inner", { opacity: 0, scale: 0 });
  gsap.set(".-box-safety .-ribbon .-inner", { opacity: 0, scale: 0 });
  gsap.set(".-box-safety .-detail p", { opacity: 0, scale: 0 });

  gsap.set(".-game-play .-before-click", { opacity: 0, y: 20 });
  gsap.set(".-game-play .-remark.-after-click", { opacity: 0, y: 20 });
  gsap.set(".-game-play .-btn-01", { opacity: 0, y: 20 });

  gsap.set(".-content-popup-reward .-box-img", { opacity: 0, scale: 0 });
  gsap.set(".-content-popup-reward .-detail p", { opacity: 0, y: 20 });
  gsap.set(".-content-popup-reward .-detail a", { opacity: 0, y: 20 });

  window.onload = () => {
    gsap.to(".-box-title h1", 0.4, {
      delay: 0.2,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });
    gsap.to(".-box-title p", 0.4, {
      delay: 0.3,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });

    gsap.to(".-box-safety", 0.4, {
      delay: 0.4,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });

    gsap.to(".-game-play .-remark.-before-click", 0.4, {
      delay: 0.5,
      opacity: 1,
      y: 0,
      ease: Back.easeOut,
    });
  };

  // document.querySelector(".-box-safety").addEventListener("click", (event) => {
  //   gsap.to(".-box-safety .-wheel", 1, {
  //     delay: 0,
  //     rotation: 360,
  //     ease: Power4.easeOut,
  //   });

  //   gsap.to(".-box-safety .-text-berlin777", 0.8, {
  //     delay: 1,
  //     opacity: 0,
  //     ease: Power4.easeOut,
  //   });
  //   gsap.to(".-box-safety .-text-open", 0.8, {
  //     delay: 1,
  //     opacity: 1,
  //     ease: Power4.easeOut,
  //   });

  //   gsap.to(".-box-safety .-safety", 0.3, {
  //     delay: 1.8,
  //     scale: 0.8,
  //     ease: Power4.easeOut,
  //   });
  //   gsap.to(".-box-safety .-safety", 0.3, {
  //     delay: 2.2,
  //     scale: 1.1,
  //     ease: Back.easeOut,
  //   });
  //   gsap.to(".-box-safety .-safety", 0.3, {
  //     delay: 2.45,
  //     scale: 1,
  //     ease: Back.easeOut,
  //   });
  //   gsap.to(".-box-safety .-group-door-close", 0, {
  //     delay: 2.3,
  //     opacity: 0,
  //     ease: Power4.easeOut,
  //   });
  //   gsap.to(".-box-safety .-door-open", 0, {
  //     delay: 2.3,
  //     opacity: 1,
  //     ease: Power4.easeOut,
  //   });
  //   gsap.to(".-box-safety .-door-open", 0.4, {
  //     delay: 2.3,
  //     rotateY: 40,
  //     ease: Power4.easeOut,
  //   });
  //   gsap.to(".-box-safety .-door-open", 0.4, {
  //     delay: 2.7,
  //     rotateY: 0,
  //     ease: Power4.easeOut,
  //   });

  //   gsap.to(".-box-safety .-detail p", 0.4, {
  //     delay: 2.3,
  //     stagger: 0.1,
  //     opacity: 1,
  //     scale: 1,
  //     ease: Back.easeOut,
  //   });
  //   gsap.to(".-box-safety .-light .-inner", 0.4, {
  //     delay: 2.3,
  //     opacity: 1,
  //     scale: 1,
  //     ease: Power4.easeOut,
  //   });
  //   gsap.to(".-box-safety .-ribbon .-inner", 0.6, {
  //     delay: 2.4,
  //     opacity: 1,
  //     scale: 1,
  //     ease: Back.easeOut,
  //   });

  //   gsap.to(".-game-play .-remark.-before-click", 0.6, {
  //     delay: 1.8,
  //     opacity: 0,
  //     ease: Back.easeOut,
  //   });
  //   setTimeout(() => {
  //     document.querySelector(
  //       ".-game-play .-remark.-before-click"
  //     ).style.display = "none";
  //   }, 2400);

  //   gsap.to(".-game-play .-remark.-after-click", 0.6, {
  //     delay: 2.4,
  //     opacity: 1,
  //     y: 0,
  //     ease: Back.easeOut,
  //   });
  //   gsap.to(".-game-play .-btn-01", 0.4, {
  //     delay: 2.5,
  //     opacity: 1,
  //     y: 0,
  //     ease: Back.easeOut,
  //   });

  //   setTimeout(() => {
  //     document.body.classList.add("-open-popup-reward");

  //     animatePopupReward();
  //   }, 4000);
  // });
};

const animateSafeOpen = () => {
  gsap.to(".-box-safety .-wheel", 1, {
    delay: 0,
    rotation: 360,
    ease: Power4.easeOut,
  });

  gsap.to(".-box-safety .-text-berlin777", 0.8, {
    delay: 1,
    opacity: 0,
    ease: Power4.easeOut,
  });
  gsap.to(".-box-safety .-text-open", 0.8, {
    delay: 1,
    opacity: 1,
    ease: Power4.easeOut,
  });

  gsap.to(".-box-safety .-safety", 0.3, {
    delay: 1.8,
    scale: 0.8,
    ease: Power4.easeOut,
  });
  gsap.to(".-box-safety .-safety", 0.3, {
    delay: 2.2,
    scale: 1.1,
    ease: Back.easeOut,
  });
  gsap.to(".-box-safety .-safety", 0.3, {
    delay: 2.45,
    scale: 1,
    ease: Back.easeOut,
  });
  gsap.to(".-box-safety .-group-door-close", 0, {
    delay: 2.3,
    opacity: 0,
    ease: Power4.easeOut,
  });
  gsap.to(".-box-safety .-door-open", 0, {
    delay: 2.3,
    opacity: 1,
    ease: Power4.easeOut,
  });
  gsap.to(".-box-safety .-door-open", 0.4, {
    delay: 2.3,
    rotateY: 40,
    ease: Power4.easeOut,
  });
  gsap.to(".-box-safety .-door-open", 0.4, {
    delay: 2.7,
    rotateY: 0,
    ease: Power4.easeOut,
  });

  gsap.to(".-box-safety .-detail p", 0.4, {
    delay: 2.3,
    stagger: 0.1,
    opacity: 1,
    scale: 1,
    ease: Back.easeOut,
  });
  gsap.to(".-box-safety .-light .-inner", 0.4, {
    delay: 2.3,
    opacity: 1,
    scale: 1,
    ease: Power4.easeOut,
  });
  gsap.to(".-box-safety .-ribbon .-inner", 0.6, {
    delay: 2.4,
    opacity: 1,
    scale: 1,
    ease: Back.easeOut,
  });

  gsap.to(".-game-play .-remark.-before-click", 0.6, {
    delay: 1.8,
    opacity: 0,
    ease: Back.easeOut,
  });
  setTimeout(() => {
    document.querySelector(
      ".-game-play .-remark.-before-click"
    ).style.display = "none";
  }, 2400);

  gsap.to(".-game-play .-remark.-after-click", 0.6, {
    delay: 2.4,
    opacity: 1,
    y: 0,
    ease: Back.easeOut,
  });
  gsap.to(".-game-play .-btn-01", 0.4, {
    delay: 2.5,
    opacity: 1,
    y: 0,
    ease: Back.easeOut,
  });

  setTimeout(() => {
    document.body.classList.add("-open-popup-reward");

    animatePopupReward();
  }, 4000);
};

const openPopupReward = () => {
  document.body.classList.add("-open-popup-reward");

  animatePopupReward();
};

const closePopupReward = () => {
  document.body.classList.remove("-open-popup-reward");

  gsap.set(".-content-popup-reward .-box-img", { opacity: 0, scale: 0 });
  gsap.set(".-content-popup-reward .-detail p", { opacity: 0, y: 20 });
  gsap.set(".-content-popup-reward .-detail a", { opacity: 0, y: 20 });
};

const animatePopupReward = () => {
  gsap.to(".-content-popup-reward .-box-img", 0.4, {
    delay: 0.2,
    opacity: 1,
    scale: 1,
    ease: Back.easeOut,
  });
  gsap.to(".-content-popup-reward .-detail p", 0.4, {
    delay: 0.4,
    stagger: 0.1,
    opacity: 1,
    y: 1,
    ease: Back.easeOut,
  });
  gsap.to(".-content-popup-reward .-detail a", 0.4, {
    delay: 0.8,
    stagger: 0.1,
    opacity: 1,
    y: 1,
    ease: Back.easeOut,
  });
};

const randomReward = () => {
  const reward = [
    "30,000",
    "50,000",
    "100,000",
    "150,000",
    "200,000",
    "250,000",
    "300,000",
    "350,000",
    "400,000",
  ];
  const getRandomReward = reward[Math.floor(Math.random() * reward.length)];

  const textReward = document.querySelectorAll(".text-reward");
  for (let [i] of textReward.entries()) {
    textReward[i].innerHTML = `฿${getRandomReward}`;
  }
};

const spinWheel = () => {
  var mySpinBtn = document.querySelector('.spinBtn');
  mySpinBtn.onclick()
}

/* End All Function */
